import React, { useState, useEffect, useCallback, memo } from "react";
import { CommonApi, HomeApi } from "../../config/api";
import { defaultHeader, toastError, toastSuccess } from "../../services/CommonFunction";
import { urls } from "../../config/constants";
import { callApi } from "../../services/ApiService";
import { commonRoutes, HomeRoutes, SuzukiRoutes } from "../../config/RouteConfig";
import MoveCursorToTop from "../../services/MoveCursorToTop";

const Aboutus = () => {
	MoveCursorToTop();
	const [data, setData] = useState([]);
	const fetchData = useCallback(async () => {
		await callApi(CommonApi.getSettings.method, CommonApi.getSettings.url, null, null, defaultHeader())
			.then((res) => {
				if (!res?.data?.error) {
					const payload = res?.data?.data;
					if (Array.isArray(payload) && payload?.length > 0) {
						setData(payload);
					}
				} else {
					// toastError(res?.data?.message);
				}
			})
			.catch((err) => {
				// toastError(err?.response?.data?.message);
			});
	}, []);
	useEffect(() => {
		fetchData();
	}, []);
	return (
		<>
			<section className='hero__banner_inner--block' style={{ backgroundImage: "url('./images/about-hero-banner.jpg')" }}>
				<div className='container'>
					<div className='page__title'>
						<h2 className='h6 text-white mb-0'>About US</h2>
					</div>
				</div>
			</section>
			<section className='about_us--block py-5 bg-white'>
				<div className='container'>
					<div className='row align-content-center'>
						<div className='col-md-4'>{data.map((d, i) => (d?.key_name == "about-image" ? <img src={`${urls.dir_url}/${d?.key_value}`} alt='About Image' /> : null))}</div>
						<div className='col-md-8'>
							<h4>Our Company</h4>
							{data.map((d, i) => (
								<p> {d?.key_name == "company" ? d?.key_value : ""}</p>
							))}
							<br />
							<h4>Our Vision</h4>
							{data.map((d, i) => (
								<p> {d?.key_name == "vision" ? d?.key_value : ""}</p>
							))}
							<br />
							<h4>CEO's Message</h4>
							{data.map((d, i) => (
								<p> {d?.key_name == "ceo" ? d?.key_value : ""}</p>
							))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Aboutus;
