import React, { useState, useEffect, useCallback, memo } from "react";
import { urls } from "../../config/constants";
import MoveCursorToTop from "../../services/MoveCursorToTop";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const ThankYou = () => {
	MoveCursorToTop();
	const params = useParams();
	return (
		<section className='thankyou_section'>
			{/* {params?.id === "gmc-currentoffers" ? (
				<Helmet>
					<script>
						{`
              gtag('event', 'conversion', {'send_to': 'AW-961878440/KRfXCM_UueEYEKiz1MoD'});
              `}
					</script>
					<script>
						{`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1263656507727963');
            fbq('track', 'PageView');
            `}
					</script>
					<noscript>{`<img height='1' width='1' style='display:none' src='https://www.facebook.com/tr?id=1263656507727963&ev=PageView&noscript=1'/>`}</noscript>
				</Helmet>
			) : null} */}

			<div className='container'>
				<div className='row'>
					<div className='col-lg-4 col-12'>
						<div className='part_text'>
							<img src={`${urls.frontendUrl}/images/thankyou.gif`} />
							<h1>شكرًا لك!</h1>
							<p></p>
							<Link className='btn' to={`/home/${params?.id}`}>
                            العودة إلى الصفحة السابقة
							</Link>
							{/*<Link className="btn" to={`${'/'}`}>Go to home</Link>*/}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ThankYou;
