import React, { useState, useEffect, useCallback, memo } from "react";
import { CommonApi, HomeApi } from "../../config/api";
import { urls } from "../../config/constants";
import { callApi } from "../../services/ApiService";
import { defaultHeader, toastError, toastSuccess } from "../../services/CommonFunction";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import { image2svg } from "../../utilsfunctions/Svg";
import { commonRoutes, HomeARRoutes, SuzukiARRoutes } from "../../config/RouteConfig";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import MoveCursorToTop from "../../services/MoveCursorToTop";
const Home = () => {
	MoveCursorToTop();

	const [banners, setBanners] = useState([]);
	const [blogs, setBlogs] = useState([]);

	const [topImages, setTopImages] = useState([]);
	const [gmc_suzuki_service_images, set_gmc_suzuki_service_images] = useState([]);
	const [gmc_trucks, set_gmc_trucks] = useState([]);
	const [middle_images, set_middle_images] = useState([]);
	const [owner, set_owner] = useState([]);

	const [data, setData] = useState([]);

	const fetchData = useCallback(async () => {
		await callApi(CommonApi.getSettings.method, CommonApi.getSettings.url, null, null, defaultHeader())
			.then((res) => {
				if (!res?.data?.error) {
					const payload = res?.data?.data;
					if (Array.isArray(payload) && payload?.length > 0) {
						setData(payload);
					}
				} else {
					// toastError(res?.data?.message);
				}
			})
			.catch((err) => {
				// toastError(err?.response?.data?.message);
			});
	}, []);

	const fetchHomeImages = useCallback(async () => {
		await callApi(HomeApi.getHomePageImages.method, HomeApi.getHomePageImages.url, null, null, defaultHeader())
			.then((res) => {
				if (!res?.data?.error) {
					// toastSuccess(res?.data?.message);
					const payload = res?.data?.data;

					if (Array.isArray(payload?.top_banner) && payload?.top_banner?.length > 0) {
						setTopImages(payload?.top_banner);
					}

					if (Array.isArray(payload?.gmc_suzuki_service) && payload?.gmc_suzuki_service?.length > 0) {
						set_gmc_suzuki_service_images(payload?.gmc_suzuki_service);
					}

					if (Array.isArray(payload?.gmctruck) && payload?.gmctruck?.length > 0) {
						set_gmc_trucks(payload?.gmctruck);
					}

					if (Array.isArray(payload?.middlebanner) && payload?.middlebanner?.length > 0) {
						set_middle_images(payload?.middlebanner);
					}

					if (Array.isArray(payload?.owner) && payload?.owner?.length > 0) {
						set_owner(payload?.owner);
					}
				} else {
					// toastError(res?.data?.message);
				}
			})
			.catch((err) => {
				toastError(err?.response?.data?.message);
			});
	}, []);

	const fetchBlogs = useCallback(async () => {
		await callApi(HomeApi.getBlogs.method, HomeApi.getBlogs.url, null, null, defaultHeader())
			.then((res) => {
				if (!res?.data?.error) {
					// toastSuccess(res?.data?.message);
					const payload = res?.data?.data;
					if (Array.isArray(payload)) {
						setBlogs(payload);
					}
				} else {
					//   toastError(res?.data?.message);
				}
			})
			.catch((err) => {
				// toastError(err?.response?.data?.message);
			});
	}, []);

	useEffect(() => {
		fetchData();
		fetchHomeImages();
		fetchBlogs();
		image2svg();
		AOS.init();
		AOS.refresh();
	}, []);

	return (
		<>
			<section className='hero__banner--block overlay gradient-overlay'>
				<div className='hero__banner--img centered-img-wrapper' data-aos='fade-down' data-aos-duration='1300'>
				<video className='lazy-load centered-img' src={`${urls.dir_url}/${topImages[0]?.image}`} autoPlay muted loop width='1920' height='1172' alt='Hero Banner' />
				</div>
				<div className='hero__banner--content text-center'>
					<div data-aos='fade-up' data-aos-duration='1100'>
                    <h1 className='text-white text-uppercase h4'>{topImages[0]?.title_arr}</h1>
						{/* <a href="#" className="btn">عرض المزيد</a> */}
						<Link to={`/${commonRoutes.aboutusRoute}`} className='btn'>
							عرض المزيد
						</Link>
					</div>
				</div>
			</section>

			<section className='car__cetegory--block blue-bg'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12'>
							<h4 className='text-white text-center text-uppercase mb-5' data-aos='fade-up'>
							ابحث عن سيارة أحلامك معنا
							</h4>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-4' data-aos='fade-up' data-aos-duration='600'>
							<div className='car__cetegory--item position-relative'>
								<div className='car__cetegory--img centered-img-wrapper'>
									{/* <a href="#"><img className="lazy-load centered-img" src={`${urls.frontendUrl}/images/category-banner-1.jpg`} width="350" height="405" alt="Category" /></a> */}
									<a href='https://gmc.moosagroup.com/' target='_blank'>
										<img className='lazy-load centered-img' src={`${urls.frontendUrl}/images/new_images/gmc/gmc-findyourdreamcar2-508x598.png`} width='350' height='405' alt='Category' />
									</a>
								</div>
								<div className='car__cetegory--name d-flex align-content-center justify-content-between bg-white w-100' style={{ padding: "15px" }}>
									<h4 className='text-uppercase mb-0'>جي ام سي</h4>
									<a href='https://gmc.moosagroup.com/' target='_blank' className='circle__arrow'>
										<img className='in-svg' src={`${urls.frontendUrl}/images/circle-arrow.svg`} alt='Arrow' />
									</a>
								</div>
							</div>
						</div>
						<div className='col-md-4' data-aos='fade-up' data-aos-duration='900'>
							<div className='car__cetegory--item position-relative'>
								<div className='car__cetegory--img centered-img-wrapper'>
									{/* <a href="#"><img className="lazy-load centered-img" src={`${urls.frontendUrl}/images/category-banner-2.jpg`} width="350" height="405" alt="Category"/></a> */}
									<Link to={`/${SuzukiARRoutes.home}`}>
										<img className='lazy-load centered-img' src={`${urls.frontendUrl}/images/new_images/suzuki/suzuki-find-your-dream-car1-508x598.png`} width='350' height='405' alt='Category' />
									</Link>
								</div>
								<div className='car__cetegory--name d-flex align-content-center justify-content-between bg-white w-100' style={{ padding: "15px" }}>
									<h4 className='text-uppercase mb-0'>سوزوكي</h4>
									<Link to={`/${SuzukiARRoutes.home}`} className='circle__arrow'>
										<img className='in-svg' src={`${urls.frontendUrl}/images/circle-arrow.svg`} alt='Arrow' />
									</Link>
								</div>
							</div>
						</div>
						<div className='col-md-4' data-aos='fade-up' data-aos-duration='1200'>
							<div className='car__cetegory--item position-relative'>
								<div className='car__cetegory--img centered-img-wrapper'>
									{/* <a href="#"><img className="lazy-load centered-img" src={`${urls.frontendUrl}/images/category-banner-3.jpg`} width="350" height="405" alt="Category"/></a> */}
									<Link to={`/${HomeARRoutes.service_enquiry}`}>
										<img className='lazy-load centered-img' src={`${urls.frontendUrl}/images/svs-findyourdreamcar-508x598-2.png`} width='350' height='405' alt='Category' />
									</Link>
								</div>
								<div className='car__cetegory--name d-flex align-content-center justify-content-between bg-white w-100' style={{ padding: "15px" }}>
									<h4 className='text-uppercase mb-0'>الخدمة</h4>
									<Link to={`/${HomeARRoutes.service_enquiry}`} className='circle__arrow'>
										<img className='in-svg' src={`${urls.frontendUrl}/images/circle-arrow.svg`} alt='Arrow' />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='about__marh--block blue-bg'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-4 col-md-12' data-aos='fade-right'>
							<div className='about__marh--col'>
								<h4 className='text-uppercase text-white'>عن موسى عبد الرحمن حسن وشركاه</h4>
								{data.map((d, i) => (
									<p key={`p` + i}> {d?.key_name == "about" ? d?.key_value_arr : ""} </p>
								))}
							</div>
						</div>
						<div className='col-lg-4 col-md-12' data-aos='fade-left'>
							<div className='about__marh--col'>
								<h4 className='text-uppercase text-white'>تاريخنا</h4>
								{data.map((h, i) => (
									<p key={`q` + i}> {h?.key_name == "history" ? h?.key_value_arr : ""} </p>
								))}
							</div>
						</div>
						<div className='col-lg-4 col-md-12' data-aos='fade-left'>
							<div className='about__marh--col'>
								<h4 className='text-uppercase text-white'>رؤيتنا</h4>
								{data.map((k, i) => (
									<p key={`r` + i}> {k?.key_name == "vision" ? k?.key_value_arr : ""} </p>
								))}
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12 mt-4 text-center'>
							<Link to={`/${commonRoutes.aboutusRoute}`} className='btn'>
								عرض المزيد
							</Link>
						</div>
					</div>
				</div>
			</section>

			{gmc_trucks.map((e, i) => (
				<section className='gmc__truck--block bg-white' key={'q'+1}>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-6 col-md-12' data-aos='fade-right'>
								<div className='gmc__truck--banner reveal centered-img-wrapper'>
									{/* <img className="lazy-load centered-img" src={`${urls.frontendUrl}/images/GMC-Truck.jpg`} width="535" height="430" alt="GMC Truck"/> */}
									<img className='lazy-load centered-img' src={`${urls.frontendUrl}/images/new_images/gmc/gmc-trucks-770x616.png`} width='535' height='430' alt='GMC Truck' />
								</div>
							</div>
							<div className='col-lg-6 col-md-12' data-aos='fade-left'>
								<div className='gmc__truck--details'>
									<div dangerouslySetInnerHTML={{ __html: e?.description_arr }}></div>
									<a href={`${e?.link}`} target='_blank' className='btn'>
										عرض المزيد
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			))}

			<section className='car__adventure--block'>
				<Swiper
					className='car__adventure__slider swiper'
					modules={[EffectFade, Navigation]}
					navigation={{
						nextEl: ".swiper-button-next.swiper-button",
						prevEl: ".swiper-button-prev.swiper-button",
					}}>
						{middle_images.map((e, i) => (
						<SwiperSlide key={`s` + i}>
							<div className='car__adventure--item' style={{ backgroundImage: "url(" + urls.dir_url + "/" + e?.image + ")" }}>
								<div className='container'>
									<div className='car__adventure--details'>
										<h4 className='text-uppercase text-white' data-aos='fade-up'>
											{e?.title_arr}
										</h4>
										<p data-aos='fade-up'>{e?.description_arr}</p>
										<p className='mt-5 mb-0' data-aos='fade-up'>
											{/* <Link to={`/${SuzukiRoutes.home}`} className="btn">عرض المزيد</Link> */}
											<a href={`${e?.link}`} target={"_blank"} className='btn'>
												عرض المزيد
											</a>
										</p>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}
					{/* <SwiperSlide>
						<div className='car__adventure--item' style={{ backgroundImage: "url('/images/new_images/suzuki/suzuki-command-every-adventure-1920x570.png')" }}>
							<div className='container'>
								<div className='car__adventure--details'>
									<h4 className='text-uppercase text-white' data-aos='fade-up'>
										COMMAND EVERY ADVENTURE.
									</h4>
									<p data-aos='fade-up'>The Stunning New Coloured Multi-Information Display provides you with all the stets as you barrel around the corners. Coloured Multi-Information Display Cruise Control Auto Gear and Automatic Climate Control </p>
									<p className='mt-5 mb-0' data-aos='fade-up'>
										<Link to={`/${SuzukiARRoutes.home}`} className='btn'>
											عرض المزيد
										</Link>
									</p>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='car__adventure--item' style={{ backgroundImage: "url('/images/cta-banner.jpg')" }}>
							<div className='container'>
								<div className='car__adventure--details'>
									<h4 className='text-uppercase text-white'>COMMAND. </h4>
									<p>The Stunning New Coloured Multi-Information Display provides you with all the stets as you barrel around the corners. Coloured Multi-Information Display Cruise Control Auto Gear and Automatic Climate Control </p>
									<p className='mt-5 mb-0'>
										<Link to={`/${SuzukiARRoutes.home}`} className='btn'>
											عرض المزيد
										</Link>
									</p>
								</div>
							</div>
						</div>
					</SwiperSlide> */}
					<div className='swiper-button swiper-button-next'></div>
					<div className='swiper-button swiper-button-prev'></div>
				</Swiper>
			</section>

			{/* <section className='ceo--block' style={{ backgroundImage: "url('/images/ceo_banner_bg.jpg')" }}>
				<div className='container'>
					<div className='ceo__main row justify-content-between align-content-center'>
						<div className='col-lg-4' data-aos='fade-left'>
							<div className='ceo__image'>
								<img className='lazy-load' src='https://api.moosagroup.com/public/images/banners/CEO-MARH2.jpg' width='330' height='300' alt='MUSTANSIR LAKDAWALA I CHIEF EXECUTIVE OFFICER ' />
							</div>
						</div>
						<div className='col-lg-7' data-aos='fade-right'>
							<div className='ceo__details'>
								<p className='font-weight-light mb-0'>
									"Nurturing this proud legacy are members of the Moosa family who, through a combination of keen market insight and perceptive leadership, continue to position the Group for strong and sustained corporate growth. Success for this new generation of business leaders is determined not by sales alone, but
									by the strength and durability..."{" "}
								</p>
								<h4 className='mt-5 text-uppercase font-weight-light'>MUSTANSIR LAKDAWALA I CHIEF EXECUTIVE OFFICER </h4>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{owner.map((e, i) => (
				<section className='ceo--block' style={{ backgroundImage: "url(" + urls.dir_url + "/" + e?.bg_image + ")" }} key={`t` + i}>
					<div className='container'>
						<div className='ceo__main row justify-content-between align-content-center'>
							<div className='col-lg-4' data-aos='fade-left'>
								<div className='ceo__image'>
									<img className='lazy-load' src={`${urls.dir_url}/${e?.image}`} width='330' height='300' alt={e?.title} />
								</div>
							</div>
							<div className='col-lg-7' data-aos='fade-right'>
								<div className='ceo__details'>
									<p className='font-weight-light mb-0'>{e?.description_arr}</p>
									<h4 className='mt-5 text-uppercase font-weight-light'>{e?.title_arr}</h4>
									<a href={`${e?.link}`} className='btn'>
									عرض المزيد
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			))}

			<section className='our__stories--block blue-bg'>
				<div className='main__title'>
					<h4 className='text-uppercase text-white' data-aos='fade-up'>
					أحدث قصصنا
					</h4>
					{/* <p className='text-white font-weight-light' data-aos='fade-up'>
						Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
					</p> */}
				</div>

				<Swiper
					className='our__stories__slider swiper'
					modules={[EffectFade, Navigation, Pagination]}
					navigation={{
						nextEl: ".swiper-button-next.swiper-button",
						prevEl: ".swiper-button-prev.swiper-button",
					}}
					slidesPerView={1}
					loop={true}
					spaceBetween={30}
					dir="rtl"
					breakpoints={{
						0: {
							spaceBetween: 15,
							slidesPerView: 1.5,
							pagination: {
								el: ".swiper-pagination",
								type: "progressbar",
								clickable: true,
							},
							autoplay: {
								delay: 5000,
								disableOnInteraction: false,
							},
						},
						768: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 4.5,
						},
					}}>
					<div className='swiper-wrapper'>
						{blogs.length > 0
							? blogs.map((b, i) => (
									<SwiperSlide key={i}>
										<div className='swiper-slide'>
											<div className='our__stories--item bg-white' data-aos='fade-up'>
												<div className='our__stories--images centered-img-wrapper'>
													<Link to={`/${HomeARRoutes.blogs}/${b?.alias}`}>
														<img className='lazy-load centered-img' src={`${urls.dir_url}/${b?.image1}`} width='370' height='210' alt='Stories' />
													</Link>
												</div>
												<div className='our__stories--details'>
													<h4>{b?.title_arr}</h4>
													<p className='font-weight-light'>{b?.short_description_arr}</p>
													<Link to={`/${HomeARRoutes.blogs}/${b?.alias}`} className='font-weight-bold'>
													اقرأ المزيد
													</Link>
												</div>
											</div>
										</div>
									</SwiperSlide>
							  ))
							: ""}
					</div>
					<div className='swiper-button swiper-button-next'></div>
					<div className='swiper-button swiper-button-prev'></div>
				</Swiper>
			</section>
		</>
	);
};

export default Home;
