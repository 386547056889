import React from 'react'
import MoveCursorToTop from '../../services/MoveCursorToTop'

function Company() {
    MoveCursorToTop();
  return (
    <section className="enquiry--block">
        <center><h2>Coming soon</h2></center>
    </section>
  )
}

export default Company