import React, { useState, useEffect, useCallback, memo } from "react";
import AOS from "aos";
import { image2svg } from "../../utilsfunctions/Svg";
import { urls } from "../../config/constants";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import SuzukiCurrentOffersForm from "./Forms/SuzukiCurrentOffersForm";
import { Helmet } from "react-helmet";
import { CommonApi } from "../../config/api";
import { callApi } from "../../services/ApiService";
import { defaultHeader } from "../../services/CommonFunction";
import MoveCursorToTop from "../../services/MoveCursorToTop";
const SuzukiCurrentOffers = () => {
	const [data, setData] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			const res = await callApi(CommonApi.getPageBanner.method, CommonApi.getPageBanner.url, null, null, defaultHeader());

			if (!res?.data?.error) {
				const payload = res?.data?.data;
				console.log(payload);
				if (Array.isArray(payload) && payload?.length > 0) {
					setData(payload);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}, []);

	MoveCursorToTop();
	const [data1, setData1] = useState([]);
	const fetchData1 = useCallback(async () => {
		await callApi(CommonApi.getSettings.method, CommonApi.getSettings.url, null, null, defaultHeader())
			.then((res) => {
				if (!res?.data?.error) {
					const arr = [];
					const payload = res?.data?.data;
					if (Array.isArray(payload) && payload?.length > 0) {
						for (let p of payload) {
							if (p?.key_name == "suzuki-current-offer") {
								setData1(p?.key_value);
								// console.log(p?.key_value);
							}
						}
					}
				} else {
					// toastError(res?.data?.message);
				}
			})
			.catch((err) => {
				// toastError(err?.response?.data?.message);
			});
	}, []);

	useEffect(() => {
		fetchData();
		fetchData1();
		image2svg();
		AOS.init();
		AOS.refresh();
	}, []);

	return (
		<>
			<section className='enquiry--block' style={{ backgroundImage: `url(${urls.dir_url}/${data1})` }}>
				<Helmet>
					<script>
						{`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '510324268066240');
            fbq('track', 'PageView');
            `}
					</script>
					<noscript>{`<img height='1' width='1' style='display:none' src='https://www.facebook.com/tr?id=510324268066240&ev=PageView&noscript=1'/>`}</noscript>
				</Helmet>

				<div className='container'>
					<div className='row'>
						<div className='col-lg-7'>
							<div className='enquiry__form bg-white'>
								<div className='car__model__slider swiper'>
									<Swiper
										className='car__adventure__slider swiper'
										autoplay={{
											delay: 2500,
											disableOnInteraction: false,
										}}
										pagination={true}
										modules={[Autoplay, Pagination, EffectFade]}>
										{data.map((d, i) =>
											d?.type == "2" && d?.pages == "3" ? (
												<SwiperSlide>
													<div className='hero__banner--img centered-img-wrapper' data-aos='fade-down' data-aos-duration='1300'>
														<img className='lazy-load centered-img' src={`${urls.dir_url}/${d?.image}`} width='1920' height='940' alt='Hero Banner' />
													</div>
												</SwiperSlide>
											) : null
										)}

										{/* <SwiperSlide>
                        <div className="hero__banner--img centered-img-wrapper" data-aos="fade-down" data-aos-duration="1300">
                            <img className="lazy-load centered-img" src={`${urls.frontendUrl}/images/suzuki_offer1.jpg`} width="1920" height="940" alt="Hero Banner"/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="hero__banner--img centered-img-wrapper" data-aos="fade-down" data-aos-duration="1300">
                            <img className="lazy-load centered-img" src={`${urls.frontendUrl}/images/suzuki_offer1.jpg`} width="1920" height="940" alt="Hero Banner"/>
                        </div>

                    </SwiperSlide> */}
									</Swiper>
								</div>
							</div>
						</div>
						<div className='col-lg-5'>
							<div className='enquiry__form bg-white'>
								<div className='page__title mb-3' style={{ textAlign: "center" }}>
									<h2 className='h6 text-uppercase text-black mb-0' style={{ borderBottom: "2px solid #000000" }}>
                  العرض الحالي من سوزوكي
									</h2>
								</div>
								<SuzukiCurrentOffersForm />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default SuzukiCurrentOffers;
