import React, { useState, useEffect, useCallback, memo } from "react";
import GMCCurrentOffersForm from "./Forms/GMCCurrentOffersForm";
import { urls } from "../../config/constants";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import { CommonApi } from "../../config/api";
import { callApi } from "../../services/ApiService";
import { defaultHeader, toastError, toastSuccess, validateJsonString } from "../../services/CommonFunction";
import { image2svg } from "../../utilsfunctions/Svg";
import { Helmet } from "react-helmet";

const GMCCurrentOffers = () => {
	const [data, setData] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			const res = await callApi(CommonApi.getPageBanner.method, CommonApi.getPageBanner.url, null, null, defaultHeader());

			if (!res?.data?.error) {
				const payload = res?.data?.data;
				if (Array.isArray(payload) && payload?.length > 0) {
					setData(payload);
          console.log(payload);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}, []);

	const [data1, setData1] = useState([]);
	const fetchData1 = useCallback(async () => {
		await callApi(CommonApi.getSettings.method, CommonApi.getSettings.url, null, null, defaultHeader())
			.then((res) => {
				if (!res?.data?.error) {
					const arr = [];
					const payload = res?.data?.data;
					if (Array.isArray(payload) && payload?.length > 0) {
						for (let p of payload) {
							if (p?.key_name == "gmc-offer") {
								setData1(p?.key_value);
								console.log(p?.key_value);
							}
						}
					}
				} else {
					// toastError(res?.data?.message);
				}
			})
			.catch((err) => {
				// toastError(err?.response?.data?.message);
			});
	}, []);
	useEffect(() => {
		fetchData();
		fetchData1();
	}, []);

	return (
		<section className='enquiry--block' style={{ backgroundImage: `url(${urls.dir_url}/${data1})` }}>
			<Helmet>
				<script async src='https://www.googletagmanager.com/gtag/js?id=AW-961878440'></script>
				<script>
					{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-961878440');
          `}
				</script>

				<script>
					{`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1263656507727963');
        fbq('track', 'PageView');
        `}
				</script>
				<noscript>{`<img height='1' width='1' style='display:none' src='https://www.facebook.com/tr?id=1263656507727963&ev=PageView&noscript=1'/>`}</noscript>
			</Helmet>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-7'>
						<div className='enquiry__form bg-white mt'>
							<div className='car__model__slider swiper'>
								<Swiper
									className='car__adventure__slider swiper'
									autoplay={{
										delay: 2500,
										disableOnInteraction: false,
									}}
									pagination={true}
									modules={[Autoplay, Pagination, EffectFade]}>
									{data.map((d, i) =>
										d?.type == "1" && d?.pages == "2" ? (
											<SwiperSlide>
												<div className='hero__banner--img centered-img-wrapper responsive_wrapper' data-aos='fade-down' data-aos-duration='1300'>
													<img className='lazy-load centered-img responsive_img' src={`${urls.dir_url}/${d?.image}`} width='1920' height='940' alt='Hero Banner' />
												</div>
											</SwiperSlide>
										) : null
									)}

									{/* <SwiperSlide>
                        <div className="hero__banner--img centered-img-wrapper" data-aos="fade-down" data-aos-duration="1300">
                            <img className="lazy-load centered-img" src={`${urls.frontendUrl}/images/gmc_offer2.jpg`} width="1920" height="940" alt="Hero Banner"/>
                        </div>

                    </SwiperSlide> */}
								</Swiper>
							</div>
						</div>
					</div>
					<div className='col-lg-5'>
						<div className='enquiry__form bg-white'>
							<div className='page__title mb-3' style={{ textAlign: "center" }}>
								<h2 className='h6 text-uppercase text-black mb-0' style={{ borderBottom: "2px solid #000000" }}>
									GMC Current Offers
								</h2>
							</div>
							<GMCCurrentOffersForm />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default GMCCurrentOffers;
