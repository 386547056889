import React, { useState, useEffect, useCallback } from "react";
import { urls } from "../../config/constants";
import MoveCursorToTop from "../../services/MoveCursorToTop";
import { useParams } from "react-router";
import { defaultHeader, validateJsonString } from "../../services/CommonFunction";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import { callApi } from "../../services/ApiService";
import { SuzukiApi } from "../../config/api";
import { Link } from "react-router-dom";
import { commonRoutes, HomeRoutes, SuzukiARRoutes } from "../../config/RouteConfig";
const Blogs = () => {
	MoveCursorToTop();
	const [blog, setBlog] = useState([]);
	const params = useParams();
	const [blogs, setBlogs] = useState([]);
	const fetchBlog = async () => {
		await callApi(SuzukiApi.getBlogsByAlias.method, `${SuzukiApi.getBlogsByAlias.url}/${params?.alias}`, null, null, defaultHeader())
			.then((res) => {
				if (!res?.data?.error) {
					// toastSuccess(res?.data?.message);
					const payload = res?.data?.data;
					if (Array.isArray(payload)) {
						const data = payload[0];

						setBlog([
							{
								title: data?.title_arr,
								short_description: data?.short_description_arr,
								alias: data?.alias,
								long_description: data?.long_description_arr,
								image1: data?.image1,
								image3: data?.image3,
								blog_banner: validateJsonString(data?.blog_banner),
							},
						]);
					}
				} else {
					//   toastError(res?.data?.message);
				}
			})
			.catch((err) => {
				// toastError(err?.response?.data?.message);
			});
	};

	const fetchBlogs = async () => {
		await callApi(SuzukiApi.getBlogs.method, `${SuzukiApi.getBlogsDetails.url}/${params?.alias}`, null, null, defaultHeader())
			.then((res) => {
				if (!res?.data?.error) {
					// toastSuccess(res?.data?.message);
					const payload = res?.data?.data;
					if (Array.isArray(payload)) {
						setBlogs(payload);
					}
				} else {
					//   toastError(res?.data?.message);
				}
			})
			.catch((err) => {
				// toastError(err?.response?.data?.message);
			});
	};

	useEffect(() => {
		console.log("Params ID " + params?.alias + "");
	}, []);

	useEffect(() => {
		fetchBlog();
		fetchBlogs();
	}, [params?.alias]);

	return (
		<>
			<section className='enquiry--block' style={{ backgroundImage: `url(${urls.dir_url}/${blog[0]?.image3})` }}>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='enquiry__form bg-white'>
								<div className='page__title mb-3' style={{ textAlign: "center" }}>
									<h2 className='h6 text-uppercase text-black mb-0' style={{ borderBottom: "2px solid #000000" }}>
										{blog[0]?.title}
									</h2>
								</div>
								{blog.length > 0 ? <span dangerouslySetInnerHTML={{ __html: blog[0]?.long_description }}></span> : <span dangerouslySetInnerHTML={{ __html: "" }}></span>}
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='our__stories--block blue-bg'>
				<div className='main__title'>
					<h4 className='text-uppercase text-white' data-aos='fade-up'>
						Our Other STORIES
					</h4>
					{/*<p className="text-white font-weight-light" data-aos="fade-up">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>*/}
				</div>

				<Swiper
					className='our__stories__slider swiper'
					modules={[EffectFade, Navigation, Pagination]}
					navigation={{
						nextEl: ".swiper-button-next.swiper-button",
						prevEl: ".swiper-button-prev.swiper-button",
					}}
					slidesPerView={1}
					loop={false}
					spaceBetween={30}
					dir='rtl'
					breakpoints={{
						0: {
							spaceBetween: 15,
							slidesPerView: 1.5,
							pagination: {
								el: ".swiper-pagination",
								type: "progressbar",
								clickable: true,
							},
							autoplay: {
								delay: 5000,
								disableOnInteraction: false,
							},
						},
						768: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 4.5,
						},
					}}>
					<div className='swiper-wrapper'>
						{blogs.length > 0
							? blogs.map((b, i) => (
									<SwiperSlide key={i}>
										<div className='swiper-slide1'>
											<div className='our__stories--item bg-white' data-aos='fade-up'>
												<div className='our__stories--images centered-img-wrapper'>
													<Link to={`/${SuzukiARRoutes.blogs}/${b?.alias}`}>
														<img className='lazy-load centered-img' src={`${urls.dir_url}/${b?.image1}`} width='370' height='210' alt='Stories' />
													</Link>
												</div>
												<div className='our__stories--details'>
													<h4>{b?.title_arr}</h4>
													<p className='font-weight-light'>{b?.short_description_arr}</p>
													<Link to={`/${SuzukiARRoutes.blogs}/${b?.alias}`} className='font-weight-bold'>
														Read More {">>"}
													</Link>
												</div>
											</div>
										</div>
									</SwiperSlide>
							  ))
							: ""}
					</div>
					<div className='swiper-button swiper-button-next'></div>
					<div className='swiper-button swiper-button-prev'></div>
				</Swiper>
			</section>
		</>
	);
};

export default Blogs;
