import HomeARApp from "./HomeAR/HomeARApp";
import SuzukiApp from "./Suzuki/SuzukiApp";
import HomeApp from "./Home/HomeApp";
import SuzukiARApp from "./SuzukiAR/SuzukiARApp";

function App() {
  return (
    <>
      <HomeApp/>
      <SuzukiApp/>
      <HomeARApp/>
      <SuzukiARApp/>
    </>
  );
}

export default App;
