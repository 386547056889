import React from "react";
import PartEnquiryForm from "./Forms/PartEnquiryForm";
import { urls } from "../../config/constants";

const PartEnquiry = () => {
	return (
		<section className='enquiry--block' style={{ backgroundImage: `url(${urls.frontendUrl}/images/swift-banner.jpg)` }}>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-5'></div>
					<div className='col-lg-7'>
						<div className='enquiry__form bg-white'>
							<div className='page__title mb-3' style={{ textAlign: "center" }}>
								<h2 className='h6 text-uppercase text-black mb-0' style={{ borderBottom: "2px solid #000000" }}>
                استفسار عن قطع الغيار
								</h2>
							</div>
							<PartEnquiryForm />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PartEnquiry;
