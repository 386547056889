import React from "react";
import { previewToaster } from "../../services/CommonFunction";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Wrapper = ({ children, header }) => {
	return (
		<>
			<Helmet htmlAttributes={{ lang: "en", dir: "ltr" }} />
			{previewToaster()}
			{header != null ? header : ""}
			<main>{children}</main>
			<Footer />
		</>
	);
};

export default Wrapper;
