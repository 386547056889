const baseurl = process.env.REACT_APP_API_URL;
module.exports = 
{
    HomeApi:{
        test:{
            method:"post",
            url:""
        },
        getBannerOfTypeHome:{
            method:"GET",
            url:`${baseurl}/banners-home`
        },
        getHomePageImages:{
            method:"GET",
            url:`${baseurl}/get-home-page-images`
        },
        contactUs:{
            method:"POST",
            url:`${baseurl}/inquiry/add-contactus`
        },
        ourNetwork:{
            method:"POST",
            url:`${baseurl}/inquiry/add-our_network`
        },
        requestQuote:{
            method:"POST",
            url:`${baseurl}/inquiry/add-request_quote`
        },
        vehicle_care_and_maintance_product:{
            method:"POST",
            url:`${baseurl}/inquiry/add-vehicle_care_maintenance_products`
        },
        part_enquiry:{
            method:"POST",
            url:`${baseurl}/inquiry/add-part_inquiry`
        },
        rent_car:{
            method:"POST",
            url:`${baseurl}/inquiry/add-rent_car`
        },
        multifranchise:{
            method:"POST",
            url:`${baseurl}/inquiry/add-multi_franchise_body_shop`
        },
        new_yukon_slt_base:{
            method:"POST",
            url:`${baseurl}/inquiry/add-new_yukon_slt_base`
        },
        download_brochures:{
            method:"POST",
            url:`${baseurl}/inquiry/add-download_brochures`
        },
        gmccurrentOffers:{
            method:"POST",
            url:`${baseurl}/inquiry/add-current_offers-gmc`
        },
        suzukicurrentOffers:{
            method:"POST",
            url:`${baseurl}/inquiry/add-current_offers-suzuki`
        },
        test_drive:{
            method:"POST",
            url:`${baseurl}/inquiry/add-test_drive`
        },
        service_offer:{
            method:"POST",
            url:`${baseurl}/inquiry/add-service_offer`
        },
        service_enquiry:{
            method:"POST",
            url:`${baseurl}/inquiry/add-service_enquiry`
        },
        book_your_service:{
            method:"POST",
            url:`${baseurl}/inquiry/add-book_your_service`
        },
        body_shop:{
            method:"POST",
            url:`${baseurl}/inquiry/add-bodyshop`
        },
        getBlogs:{
            method:"GET",
            url:`${baseurl}/get-home-blogs`
        },
        getBlogsDetails:{
            method:"GET",
            url:`${baseurl}/get-home-blogs-details`
        },
        getBlogsByAlias:{
            method:"GET",
            url:`${baseurl}/get-home-blogs`
        }
        
    },
    SuzukiApi:{
        getBannerOfTypeSuzuki:{
            method:"GET",
            url:`${baseurl}/banners-suzuki`
        },
        getCarsOfSuzuki:{
            method:"GET",
            url:`${baseurl}/cars-suzuki`
        },
        getHomeBannersOfSuzuki:{
            method:"GET",
            url:`${baseurl}/banners-suzuki`
        },
        getBlogs:{
            method:"GET",
            url:`${baseurl}/get-suzuki-blogs`
        },
        getBlogsDetails:{
            method:"GET",
            url:`${baseurl}/get-suzuki-blogs-details`
        },
        getBlogsByAlias:{
            method:"GET",
            url:`${baseurl}/get-suzuki-blogs`
        }
    },
    CommonApi:{
        getMstCar:{
            method:"GET",
            url:`${baseurl}/mstcars`
        },
        getLocations:{
            method:"GET",
            url:`${baseurl}/get-location-list`
        },
        getFranchise:{
            method:"GET",
            url:`${baseurl}/get-franchise-list`
        },
        getBooking:{
            method:"GET",
            url:`${baseurl}/get-booking-list`
        },
        getChoice:{
            method:"GET",
            url:`${baseurl}/get-choice-list`
        },
        getMaintenanceProduct:{
            method:"GET",
            url:`${baseurl}/get-maintenanace_product-list`
        },
        getVehicleTypes:{
            method:"GET",
            url:`${baseurl}/get-vehicle_types-list`
        },
        getCarsOfHome:{
            method:"GET",
            url:`${baseurl}/cars-home`
        },
        getCarsOfSuzuki:{
            method:"GET",
            url:`${baseurl}/cars-suzuki`
        },
        getCarDetailsById:{
            method:"GET",
            url:`${baseurl}/car_details_bycarid`
        },
        getLocation:{
            method:"GET",
            url:`${baseurl}/location`
        },
        getAccolades:{
            method :"GET",
            url:`${baseurl}/get-accolades`
        },
        getSettings:{
            method:"GET",
            url:`${baseurl}/get-settings-menu-price`
        },
        getBrochure:{
            method:"GET",
            url:`${baseurl}/get-brochure`
        },
        getMilestone:{
            method:"GET",
            url:`${baseurl}/get-milestone`
        },
        getPageBanner:{
            method:"GET",
            url:`${baseurl}/get-page-banner`
        },
        getMaintenance:{
            method:"GET",
            url:`${baseurl}/get-maintenance`
        }
    }
}