const homeRoot = "home";
const suzukiRoot = "suzuki";
const homeARRoot = "homear";
const suzukiARRoot = "suzukiar";
const testRoot = "test";
module.exports =
{
    HomeRoutes:{
        home:homeRoot,
        companyRoute:`${homeRoot}/company`,
        ownersRoute:`${homeRoot}/owners`,
        contactusRoute:`${homeRoot}/contact-us`,
        ournetworkRoute:`${homeRoot}/our-network`,
        requestQuoteRoute:`${homeRoot}/request-quote`,
        vehicleCareAndMaintenanceRoute:`${homeRoot}/vehicle-care-maintenance-products`,
        bmeppRoute:`${homeRoot}/BM-EPP`,
        partEnquiryRoute:`${homeRoot}/part-enquiry`,
        rentCarRoute:`${homeRoot}/rent-car`,
        multifranchiseRoute:`${homeRoot}/multifranchise`,
        new_yukon_slt_base:`${homeRoot}/new_yukon_slt_base`,
        downloadBrochures:`${homeRoot}/download-brochures`,
        test_drive:`${homeRoot}/testdrive`,
        service_offer:`${homeRoot}/service_offer`,
        service_enquiry:`${homeRoot}/service_enquiry`,
        body_shop:`${homeRoot}/body_shop`,
        book_your_service:`${homeRoot}/book_your_service`,
        moosa_connect:`${homeRoot}/moosa-connect`,
        blogs:`${homeRoot}/blogs`,
        download:`${homeRoot}/download`,
        menuprice:`${homeRoot}/menu-price`,
        umbrella:`${homeRoot}/our-umbrella`,
        gmccurrentoffers:`${homeRoot}/gmc-currentoffers`,
        suzukicurrentoffers:`${homeRoot}/suzuki-currentoffers`,
        milestone:`${homeRoot}/milestone`,
        accolades:`${homeRoot}/accolades`,
        aboutusRoute:`${homeRoot}/aboutus`,
        thankyou:`${homeRoot}/thankyou`,
    },
    SuzukiRoutes:{
        home:suzukiRoot,
        carmodelRoute:`${suzukiRoot}/carmodel`,
        carmodelNotFound:`${suzukiRoot}/carmodel-notfound`,
        contactusRoute:`${suzukiRoot}/contact-us`,
        test_drive:`${suzukiRoot}/testdrive`,
        enquiryRoute:`${suzukiRoot}/enquiry`,
        offers:`${suzukiRoot}/offers`,
        service_enquiry:`${suzukiRoot}/service-enquiry`,
        partEnquiryRoute:`${suzukiRoot}/part-enquiry`,
        ournetworkRoute:`${suzukiRoot}/our-network`,
        requestQuoteRoute:`${suzukiRoot}/request-a-quote`,
        bmeppRoute:`${suzukiRoot}/BM-EPP`,
        moosaconnect:`${suzukiRoot}/moosaconnect`,
        downloadBrochures:`${suzukiRoot}/download-brochures`,
        aboutusRoute:`${suzukiRoot}/aboutus`,
        blogs:`${suzukiRoot}/blogs`,
        download:`${suzukiRoot}/download`,
        menuprice:`${suzukiRoot}/menu-price`,
        body_shop:`${suzukiRoot}/body_shop`,
        suzukicurrentoffers:`${suzukiRoot}/suzuki-currentoffers`,
        book_your_service:`${suzukiRoot}/book_your_service`,
        maintenance:`${suzukiRoot}/maintenance`,
        thankyou:`${suzukiRoot}/thankyou`,
    },
    commonRoutes:{
        carmodel:"carmodel",
        moosa_connect:"moosa-connect",
        aboutusRoute:"aboutus",
        historyRoute:"our-history"

    },
    HomeARRoutes:{
        home:homeARRoot,
        companyRoute:`${homeARRoot}/company`,
        ownersRoute:`${homeARRoot}/owners`,
        contactusRoute:`${homeARRoot}/contact-us`,
        ournetworkRoute:`${homeARRoot}/our-network`,
        requestQuoteRoute:`${homeARRoot}/request-quote`,
        vehicleCareAndMaintenanceRoute:`${homeARRoot}/vehicle-care-maintenance-products`,
        bmeppRoute:`${homeARRoot}/BM-EPP`,
        partEnquiryRoute:`${homeARRoot}/part-enquiry`,
        rentCarRoute:`${homeARRoot}/rent-car`,
        multifranchiseRoute:`${homeARRoot}/multifranchise`,
        new_yukon_slt_base:`${homeARRoot}/new_yukon_slt_base`,
        downloadBrochures:`${homeARRoot}/download-brochures`,
        test_drive:`${homeARRoot}/testdrive`,
        service_offer:`${homeARRoot}/service_offer`,
        service_enquiry:`${homeARRoot}/service_enquiry`,
        body_shop:`${homeARRoot}/body_shop`,
        book_your_service:`${homeARRoot}/book_your_service`,
        moosa_connect:`${homeARRoot}/moosa-connect`,
        blogs:`${homeARRoot}/blogs`,
        download:`${homeARRoot}/download`,
        menuprice:`${homeARRoot}/menu-price`,
        gmccurrentoffers:`${homeARRoot}/gmc-currentoffers`,
        suzukicurrentoffers:`${homeARRoot}/suzuki-currentoffers`,
        aboutusRoute:`${homeARRoot}/aboutus`,
        historyRoute:`${homeARRoot}/our-history`,
        thankyou:`${homeARRoot}/thankyou`,
    },
    SuzukiARRoutes:{
        home:suzukiARRoot,
        carmodelRoute:`${suzukiARRoot}/carmodel`,
        carmodelNotFound:`${suzukiARRoot}/carmodel-notfound`,
        test_drive:`${suzukiARRoot}/testdrive`,
        enquiryRoute:`${suzukiARRoot}/enquiry`,
        offers:`${suzukiARRoot}/offers`,
        service_enquiry:`${suzukiARRoot}/service-enquiry`,
        partEnquiryRoute:`${suzukiARRoot}/part-enquiry`,
        ournetworkRoute:`${suzukiARRoot}/our-network`,
        requestQuoteRoute:`${suzukiARRoot}/request-a-quote`,
        bmeppRoute:`${suzukiARRoot}/BM-EPP`,
        moosaconnect:`${suzukiARRoot}/moosaconnect`,
        downloadBrochures:`${suzukiARRoot}/download-brochures`,
        aboutusRoute:`${suzukiARRoot}/aboutus`,
        blogs:`${suzukiARRoot}/blogs`,
        download:`${suzukiARRoot}/download`,
        menuprice:`${suzukiARRoot}/menu-price`,
        body_shop:`${suzukiARRoot}/body_shop`,
        suzukicurrentoffers:`${suzukiARRoot}/suzuki-currentoffers`,
        book_your_service:`${suzukiARRoot}/book_your_service`,
        maintenance:`${suzukiARRoot}/maintenance`,
    },
    testRoute:{
        testTab:`${testRoot}`
    }
}