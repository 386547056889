import React from 'react'
import { previewToaster } from '../../services/CommonFunction'
import Footer from './Footer'
import Header from './Header'
import { Helmet } from "react-helmet";


//Home Wrapper
const Wrapper = ({children,header}) => {
  return (
     <>
     <Helmet htmlAttributes={{ lang: "ar", dir:"rtl" }} />
     {previewToaster()}
      {header!=null ? header : ""}
      <main>
        {children}
      </main>
     <Footer/>
     </>
  )
}

export default React.memo(Wrapper)