import PartEnquiryForm from "./Forms/PartEnquiryForm";
import React, { useState, useEffect, useCallback, memo } from "react";
import { CommonApi, HomeApi } from "../../config/api";
import { defaultHeader, toastError, toastSuccess } from "../../services/CommonFunction";
import { urls } from "../../config/constants";
import { callApi } from "../../services/ApiService";
import { commonRoutes, HomeRoutes, SuzukiRoutes } from "../../config/RouteConfig";
import MoveCursorToTop from "../../services/MoveCursorToTop";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper";

const PartEnquiry = () => {
	MoveCursorToTop();
	const [data, setData] = useState([]);
	const fetchData = useCallback(async () => {
		await callApi(CommonApi.getSettings.method, CommonApi.getSettings.url, null, null, defaultHeader())
			.then((res) => {
				if (!res?.data?.error) {
					const arr = [];
					const payload = res?.data?.data;
					if (Array.isArray(payload) && payload?.length > 0) {
						for (let p of payload) {
							if (p?.key_name == "parts-enquiry") {
								setData(p?.key_value);
								console.log(p?.key_value);
							}
						}
					}
				} else {
					// toastError(res?.data?.message);
				}
			})
			.catch((err) => {
				// toastError(err?.response?.data?.message);
			});
	}, []);
	const [data1, setData1] = useState([]);

	const fetchData1 = useCallback(async () => {
		try {
			const res = await callApi(CommonApi.getPageBanner.method, CommonApi.getPageBanner.url, null, null, defaultHeader());

			if (!res?.data?.error) {
				const payload = res?.data?.data;
				console.log(payload);
				if (Array.isArray(payload) && payload?.length > 0) {
					setData1(payload);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}, []);

	useEffect(() => {
		fetchData();
		fetchData1();
	}, []);
	return (
		<>
			<section className='hero__banner_inner--block' style={{ backgroundImage: "url('./images/about-hero-banner.jpg')" }}>
				<div className='container'>
					<div className='page__title'>
						<h2 className='h6 text-white mb-0'>About US</h2>
					</div>
				</div>
			</section>
			<section className='enquiry--block' style={{ backgroundImage: `url(${urls.dir_url}/${data})` }}>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-7'>
							<div className='enquiry__form bg-white mt'>
								<div className='car__model__slider swiper'>
									<Swiper
										className='car__adventure__slider swiper'
										autoplay={{
											delay: 2500,
											disableOnInteraction: false,
										}}
										pagination={true}
										modules={[Autoplay, Pagination, EffectFade]}>
										{data1.map((d, i) =>
											d?.type == "1" && d?.pages == "5" ? (
												<SwiperSlide>
													<div className='hero__banner--img centered-img-wrapper responsive_wrapper' data-aos='fade-down' data-aos-duration='1300'>
														<img className='lazy-load centered-img responsive_img' src={`${urls.dir_url}/${d?.image}`} width='1920' height='940' alt='Hero Banner' />
													</div>
												</SwiperSlide>
											) : null
										)}

										{/* <SwiperSlide>
                        <div className="hero__banner--img centered-img-wrapper" data-aos="fade-down" data-aos-duration="1300">
                            <img className="lazy-load centered-img" src={`${urls.frontendUrl}/images/gmc_offer2.jpg`} width="1920" height="940" alt="Hero Banner"/>
                        </div>

                    </SwiperSlide> */}
									</Swiper>
								</div>
							</div>
						</div>
						<div className='col-lg-5'>
							<div className='enquiry__form bg-white'>
								<div className='page__title mb-3' style={{ textAlign: "center" }}>
									<h2 className='h6 text-uppercase text-black mb-0' style={{ borderBottom: "2px solid #000000" }}>
										parts enquiry
									</h2>
								</div>
								<PartEnquiryForm />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default PartEnquiry;
