import $ from 'jquery';

export const image2svg = () => {
    try {
        $('.in-svg').each(function (i, e) {
            console.log("SVG");
            var $img = $(e);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            $.get(imgURL, function (data) {
                // Get the SVG tag, ignore the rest
                var $svg = $(data).find('svg');
                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', ' ' + imgClass + ' replaced-svg');
                }
                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');
                // Replace image with new SVG
                $img.replaceWith($svg);
            }, 'xml');
        });
    }
    catch (err) {
        console.log(err);
    }
}

// export const image2svg = () => {
//     try {
//         jQuery('.in-svg').each(function (i, e) {
//             var $img = jQuery(e);
//             var imgID = $img.attr('id');
//             var imgClass = $img.attr('class');
//             var imgURL = $img.attr('src');

//             jQuery.get(imgURL, function (data) {
//                 // Get the SVG tag, ignore the rest
//                 var $svg = jQuery(data).find('svg');
//                 // Add replaced image's ID to the new SVG
//                 if (typeof imgID !== 'undefined') {
//                     $svg = $svg.attr('id', imgID);
//                 }
//                 // Add replaced image's classes to the new SVG
//                 if (typeof imgClass !== 'undefined') {
//                     $svg = $svg.attr('class', ' ' + imgClass + ' replaced-svg');
//                 }
//                 // Remove any invalid XML tags as per http://validator.w3.org
//                 $svg = $svg.removeAttr('xmlns:a');
//                 // Replace image with new SVG
//                 $img.replaceWith($svg);
//             }, 'xml');
//         });
//     }
//     catch (err) {
//         console.log(err);
//     }
// }