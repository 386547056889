import React, { useEffect } from 'react'
import AOS from 'aos';
import { image2svg } from '../../utilsfunctions/Svg';
import { urls } from '../../config/constants';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Navigation,Pagination, EffectFade, Autoplay } from 'swiper'

const Umbrella = () => {

  useEffect(()=>{

    image2svg();
    AOS.init();
    AOS.refresh();
  },[]);

  return (
    <>
     <section className="epp--block bg-white pb-5"  style={{paddingTop:"205px"}}>
            <div className="container">
            <div className="page__title">
                <h2 className="h6 text-uppercase">OUR UMBRELLA</h2>
            </div>
                <div className="row">
                    <div className="col-md-3">
                        <a href="https://gmc.moosagroup.com/" target="_blank" >
                            <img src='https://api.moosagroup.com/public/images/default/GMC.jpg' />
                        </a>
                    </div>
                    <div className="col-md-3">
                        <a href="https://frontend.moosagroup.com/suzuki" target="_blank" >
                        <img src='https://api.moosagroup.com/public/images/default/SUZUKI.jpg'/>
                        </a>
                    </div>
                    <div className="col-md-3">
                        <a href="https://www.sixt.global/php/reservation/home?language=en_GB&posl=OM&land=OM&fir=412" target="_blank" >
                        <img src='https://api.moosagroup.com/public/images/default/SIXT.jpg'/>
                        </a>
                    </div>
                    <div className="col-md-3">
                        <a href="https://frontend.moosagroup.com/home/part-enquiry" target="_blank" >
                        <img src='https://api.moosagroup.com/public/images/default/SERVICES.jpg'/>
                        </a>
                    </div>
                    </div> 
                    {/* <div className="row mt-3">
                    
                    </div>
                    
                   */}
            </div>
        </section>
        
    </>
  )
}

export default Umbrella